<template>
  <div class="main-content">
    <el-row>
      <el-col :span="24">
        <content-header :title-list="titleList"></content-header>
      </el-col>
    </el-row>
    <Detail v-if="show" :id="id" :is-edit="true" :schoolId="schoolId" />
  </div>
</template>

<script>
import Detail from "./components/Detail";
import ContentHeader from "@/components/Common/ContentHeader";

export default {
  components: { Detail, ContentHeader },
  data() {
    return {
      titleList: [
        {
          text: "合作项目",
          isCur: false,
          link: ""
        },
        {
          text: "修改",
          isCur: true,
          link: ""
        }
      ],
      show: false,
      id: ""
    };
  },
  created() {
    let { id, schoolId } = this.$route.query;
    this.id = id;
    this.schoolId = schoolId;
    this.show = true;
  }
};
</script>
